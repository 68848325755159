import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import { COLORS } from '../../../constants/colours';
import { FONTS } from '../../../enums/fonts';
import { Text } from '../../../models/text';
import {
  adjustArrayValues,
  createArrayOfLength,
  getSortData,
} from '../../../helpers/arrays';
import LayoutService from '../../../services/layout';
import { type ISceneMeshesBase, Scene } from '../../../models/scene';
import { BallShaded } from '../../../models/ballShaded';
import { ColorPlane } from '../../../models/colorPlane';
import { ImagePlane } from '../../../models/imagePlane';
import { BoostText } from '../../../models/boostText';
import { AnimationPlane } from '../../../models/animationPlane';
import { SceneType, type DrawStateScene } from '../../../interfaces/sceneState';
import { type ILoExScene } from '../../../interfaces/scene';
import { templateTexts } from '../../../helpers/strings';
import { FeatureFlag } from '../../../enums/featureFlag';
import { ISettings } from '../../../interfaces/app';
import { sendMessage } from '../../../helpers/message';
import explosionYellowImage from './../../../assets/images/animations/explosion-yellow-animation.png';
import dotImage from './../../../assets/images/dot.png';

const ballTimeDiff = 5000;
interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallShaded[];
  ballPlaceholders: BallShaded[];
  cover?: ColorPlane;
  logo?: ImagePlane;
  drawInProgress?: Text;
  boost?: ImagePlane;
  boostValue?: BoostText;
  explosion?: AnimationPlane;
  dot?: AnimationPlane;
}

export default class DrawScene extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: DrawStateScene;
  protected _language: string;
  protected _featureFlags: FeatureFlag[];

  protected _meshes: ISceneMeshes = {
    balls: [],
    ballPlaceholders: [],
  };

  private _isSorted: boolean = false;
  private _ballInterval: NodeJS.Timeout | null = null;
  private _ballPool: BallShaded[] = [];
  private _isReorganizing: boolean = false;

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: DrawStateScene,
    settings: ISettings,
  ): Promise<void> => {
    this._state = state;
    this._language = language;
    this._featureFlags = settings.featureFlags;

    const {
      _meshes: meshes,
      _scene: scene,
      _state: {
        meta: {
          [language]: { boostImage, bottomText, logoImage, id, boost },
        },
      },
    } = this;

    this._isSorted = this._state.scene === SceneType.Reorder;
    scene.add((meshes.cover = new ColorPlane()));

    scene.add((meshes.cover = new ColorPlane()));
    scene.add((meshes.logo = new ImagePlane()));
    scene.add((meshes.boost = new ImagePlane()));
    scene.add((meshes.boostValue = new BoostText()));
    scene.add((meshes.drawInProgress = new Text()));
    scene.add((meshes.dot = new AnimationPlane()));
    scene.add((meshes.explosion = new AnimationPlane()));

    await Promise.all<any>([
      meshes.explosion.configure({
        images: [
          {
            arrangement: [8, 8],
            totalFrames: 64,
            url: explosionYellowImage,
          },
        ],
        state: {
          position: [0, 0, -3],
          scale: [5.5, 4, 4],
          rotation: [0, 0, 0],
        },
      }),
      meshes.dot.configure({
        images: [
          {
            arrangement: [2, 2],
            totalFrames: 2,
            url: dotImage,
          },
        ],
        state: {
          position: [-4.025, -2.2, -3.5],
          scale: [0.11, 0.11, 0.11],
          rotation: [0, 0, 0],
        },
      }),
      meshes.cover.configure({
        color: COLORS.white,
        state: {
          position: [0, 0, -3.4],
          rotation: [0, 0, 0],
          scale: [8.92, 5.0175, 1],
          opacity: 0,
        },
      }),
      meshes.logo.configure({
        dimensions: { height: 256, width: 512 },
        urls: [logoImage],
        state: {
          position: [-3.25, 2, -3.5],
          scale: [2, 0.575, 1],
          rotation: [0, 0, 0],
          opacity: 0,
        },
      }),
      meshes.boost.configure({
        dimensions: { height: 128, width: 256 },
        urls: [boostImage],
        state: {
          position: [2.65, 2, -3.5],
          scale: [1.5, 0.5, 1],
          rotation: [0, 0, 0],
          opacity: 0,
        },
      }),
      meshes.boostValue.configure({
        dimensions: { height: 64, width: 128 },
        text: [
          {
            text: 'x',
            font: FONTS.AristaProBold,
            color: COLORS.white,
            size: 50,
            // fillStyle: COLORS.white,
            // strokeStyle: COLORS.brightRed,
            // strokeWidth: '2',
          },
          {
            text: `${boost}`,
            font: FONTS.AristaProBold,
            color: COLORS.white,
            size: 64,
            // fillStyle: COLORS.white,
            // strokeStyle: COLORS.brightRed,
            // strokeWidth: '2',
          },
        ],
        state: {
          position: [4.1, 1.975, -3.5],
          scale: [0, 0.4, 1],
          rotation: [0, 0, 0],
          opacity: 0,
        },
        textAlign: 'right',
        textBaseline: 'alphabetic',
      }),
      meshes.drawInProgress.configure({
        text: templateTexts(bottomText, { id }),
        state: {
          position: [-3.9, -2.2, -3.5],
          scale: [0, 0.2, 1],
          rotation: [0, 0, 0],
          opacity: 0,
        },
        textAlign: 'left',
      }),
    ]);
  };

  public play = async (): Promise<void> => {
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: { scene },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }
      const isReorderScene = scene === SceneType.Reorder;

      return await Promise.all<any>([
        meshes.drawInProgress.tweenTo({ opacity: 1 }),
        meshes.boostValue.tweenTo({ opacity: 1 }),
        meshes.boost.tweenTo({ opacity: 1 }),
        meshes.logo.tweenTo({ opacity: 1 }),
        meshes.dot.beginAnimation(1000),
        isReorderScene
          ? this.synchroniseBalls()
          : this.ballAnimationWithSmartPooling(),
      ])
        .then(() => resolve())
        .catch(reject);
    });
  };

  public onChange = async (state: DrawStateScene): Promise<void> => {
    this._state = state;
    const { scene } = state;

    const isReorderScene = scene === SceneType.Reorder;

    try {
      if (!this._isSorted && isReorderScene) {
        this._isSorted = true;
        await this.reorganiseBalls(false, true);
      } else if (this._isSorted && !isReorderScene) {
        this._isSorted = false;
        await this.reorganiseBalls(true, false);
      }
    } catch (error) {
      console.log('Error in onChange:', error);
    }
  };

  public teardown = async (): Promise<void> => {
    if (this._ballInterval) {
      clearInterval(this._ballInterval);
      this._ballInterval = null;
    }

    const { _meshes: meshes } = this;

    if (!Object.keys(meshes).length) {
      await this.destroy();
      return;
    }

    try {
      const tweenPromises = [];

      if (meshes.cover) {
        tweenPromises.push(meshes.cover.tweenTo({ opacity: 1 }, 1000));
      }

      tweenPromises.push(
        ...meshes.balls.map((ball) => ball.tweenOut({ opacity: 0 })),
        ...meshes.ballPlaceholders.map((ball) => ball.tweenOut({ opacity: 0 })),
      );

      await Promise.all(tweenPromises);

      this._meshes.balls.forEach((ball) => this.returnBallToPool(ball));
      this._meshes.balls = [];
      this._meshes.ballPlaceholders.forEach((ball) =>
        this.returnBallToPool(ball),
      );
      this._meshes.ballPlaceholders = [];

      await this.destroy();
    } catch (error) {
      console.log('Error in teardown:', error);
    }
  };

  private synchroniseBalls = async (): Promise<void> => {
    const {
      _meshes: meshes,
      _scene: scene,
      _state: {
        meta: {
          [this._language]: { balls },
        },
      },
    } = this;
    const currentResults = getSortData(balls, (a, b) => a - b);
    const ballLayout = LayoutService.ballGridLayout();

    createArrayOfLength(20).forEach((_, i) => {
      this.addBallToPos(
        ballLayout,
        currentResults,
        i,
        scene,
        meshes.balls,
        currentResults[i].value,
        false,
      );
    });
  };

  private ballAnimationWithSmartPooling = async (): Promise<void> => {
    const {
      _meshes: meshes,
      _scene: scene,
      _state: {
        duration,
        progress,
        startTime,
        meta: {
          [this._language]: { balls },
        },
      },
    } = this;
    const totNbBalls = 20;
    const ballLayout = LayoutService.ballGridLayout();

    const nbOfBalls = Math.floor((progress - startTime) / ballTimeDiff);
    const currentResults = getSortData(balls, (a, b) => a - b);

    let ballID = Math.max(nbOfBalls - 1, 0);

    createArrayOfLength(totNbBalls).forEach((_, i) => {
      const isBall = i < nbOfBalls - 1;
      if (isBall) {
        this.addBallToPos(
          ballLayout,
          currentResults,
          i,
          scene,
          meshes.balls,
          currentResults[i].value,
          false,
        );
      }
    });

    if (this._ballInterval) {
      clearInterval(this._ballInterval);
    }

    if (nbOfBalls < totNbBalls) {
      const animateBall = () => {
        if (ballID >= totNbBalls) {
          if (this._ballInterval) clearInterval(this._ballInterval);
          return;
        }
        this.addBallToPos(
          ballLayout,
          currentResults,
          ballID,
          scene,
          meshes.balls,
          currentResults[ballID].value,
          true,
          document.visibilityState === 'visible',
        );
        ballID++;
      };

      setTimeout(
        () => {
          animateBall();
          this._ballInterval = setInterval(() => {
            animateBall();
          }, ballTimeDiff);
        },
        ballTimeDiff - (progress % ballTimeDiff),
      );
    }

    await new Promise<void>((resolve) => {
      setTimeout(() => resolve(), duration - progress);
    });
  };

  private async reorganiseBalls(from: boolean, to: boolean): Promise<void> {
    if (this._isReorganizing) {
      console.log('Reorganization already in progress. Skipping.');
      return;
    }

    this._isReorganizing = true;

    try {
      const {
        _state: {
          meta: {
            [this._language]: { balls = [] },
          },
        },
      } = this;

      const currentResults = getSortData(balls, (a, b) => a - b);
      const ballLayout = LayoutService.ballGridLayout();

      // check delta time, if delta time bigger than the whole ball animation time, then skip the animation
      const skipAnimation = async () => {
        if (document.visibilityState !== 'visible') {
          await this.instantAnimateBallsIn(from, currentResults, ballLayout);
          return true;
        }
        return false;
      };

      if (skipAnimation()) return;
      await this.animateBallsOut(from, currentResults, ballLayout);
      if (skipAnimation()) return;
      await this.changeBallPositions(to, currentResults, ballLayout);
      if (skipAnimation()) return;
      await this.animateBallsIn(to, currentResults, ballLayout);
    } catch (error) {
      console.log('Error in reorganiseBalls:', error);
    } finally {
      this._isReorganizing = false;
    }
  }

  private async animateBallsOut(
    from: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(from);
    await Promise.all(
      balls.map((ball, i) =>
        ball?.tweenTo(
          {
            scale: [0.0001, 0.0001, 0.0001],
            rotation: [0, 0, -Math.PI * 4],
            opacity: 0,
          },
          750,
          ballLayout.animationOffset[currentResults[i]?.originalIndex ?? i],
          TWEEN.Easing.Quadratic.In,
        ),
      ),
    );
  }

  private async changeBallPositions(
    to: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(to);
    await Promise.all(
      balls.map((ball, i) =>
        ball?.changeTo({
          position: ballLayout.positions[currentResults[i]?.originalIndex ?? i],
          rotation: [0, 0, Math.PI * 4],
        }),
      ),
    );
  }

  private async animateBallsIn(
    to: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(to);
    await Promise.all(
      balls.map((ball, i) =>
        ball?.tweenTo(
          {
            scale: ballLayout.scale,
            rotation: [0, 0, 0],
            opacity: 1,
          },
          750,
          ballLayout.animationOffset[currentResults[i]?.originalIndex ?? i],
          TWEEN.Easing.Quadratic.Out,
        ),
      ),
    );
  }

  private async instantAnimateBallsIn(
    to: boolean,
    currentResults: any[],
    ballLayout: any,
  ): Promise<void> {
    const balls = this.getBalls(to);
    await Promise.all(
      balls.map((ball, i) =>
        // directly set the position and opacity to 1
        ball?.changeTo({
          position: ballLayout.positions[currentResults[i]?.originalIndex ?? i],
          opacity: 1,
        }),
      ),
    );
  }

  private getBalls(ordered: boolean): (BallShaded | null)[] {
    const {
      _meshes: meshes,
      _state: {
        meta: {
          [this._language]: { balls },
        },
      },
    } = this;

    if (!meshes.balls || meshes.balls.length === 0) {
      return [];
    }

    const currentResults = getSortData(balls, (a, b) => a - b);
    return meshes.balls.map((_, i) => {
      const index =
        currentResults[i]?.[ordered ? 'sortedIndex' : 'originalIndex'] ?? i;
      return meshes.balls[index] || null;
    });
  }

  private getBallFromPool(): BallShaded {
    if (this._ballPool.length > 0) {
      return this._ballPool.pop()!;
    }
    return new BallShaded();
  }

  private returnBallToPool(ball: BallShaded) {
    this._ballPool.push(ball);
  }

  private addBall(
    value: number | undefined,
    i: number,
    position: [number, number, number],
    animate: boolean,
    toAnim: boolean,
  ): BallShaded {
    const { _meshes: meshes } = this;
    const ball = this.getBallFromPool();
    const ballLayout = LayoutService.ballGridLayout();
    // check delta time, if delta time bigger than the whole ball animation time, then skip the animation
    const skipAnimation = async () => {
      if (!toAnim) {
        await ball.changeTo({ position, opacity: 1 }, 0);
        if (value && meshes.ballPlaceholders && meshes.ballPlaceholders[i]) {
          await meshes.ballPlaceholders[i].tweenTo({ opacity: 0 }, 0);
        }
        return true;
      }
      return false;
    };

    const configureBall = async () => {
      await ball.configure({
        dimensions: { height: animate ? 256 : 256, width: animate ? 256 : 256 },
        value,
        state: {
          position: animate
            ? [0, 0, -1.25]
            : adjustArrayValues(position, [0, 0, -1]),
          rotation: [0, 0, 0],
          scale: ballLayout.scale,
          opacity: 0,
        },
        featureFlag: FeatureFlag.Default,
      });
    };

    const animateBallIn = async () => {
      if (animate) {
        if (await skipAnimation()) return;
        await Promise.all([
          ball.tweenTo(
            { position: [0, 0, -1], opacity: 1 },
            1500,
            1000,
            TWEEN.Easing.Quadratic.Out,
          ),
        ]);
        await ball.tweenTo({ position, opacity: 1 }, 1000);
      } else {
        await ball.tweenTo(
          {
            position,
            opacity:
              meshes.ballPlaceholders && meshes.ballPlaceholders[i] ? 0 : 1,
          },
          1000,
          i * 100,
          TWEEN.Easing.Elastic.Out,
        );
      }
    };

    const updatePlaceholder = async () => {
      if (value && meshes.ballPlaceholders && meshes.ballPlaceholders[i]) {
        await meshes.ballPlaceholders[i].changeTo({ opacity: 0 }, 125);
      }
    };

    configureBall()
      .then(animateBallIn)
      .then(() => {
        sendMessage('draw:ball', value);
        return updatePlaceholder();
      })
      .catch((error) => console.log('Error in addBall:', error));

    return ball;
  }

  private addBallToPos(
    ballLayout: any,
    currentResults: any[],
    ballID: number,
    scene: THREE.Scene,
    meshesBall: BallShaded[],
    ballValue: number | undefined,
    anim: boolean,
    toAnim: boolean = false,
  ) {
    const position = ballLayout.positions[currentResults[ballID].originalIndex];
    if (meshesBall[ballID]) {
      scene.remove(meshesBall[ballID]);
      this.returnBallToPool(meshesBall[ballID]);
    }
    meshesBall[ballID] = this.addBall(
      ballValue,
      ballID,
      position,
      anim,
      toAnim,
    );
    scene.add(meshesBall[ballID]);
  }
}
