import { useState, useEffect } from 'react';

export function useRescale() {
  const scaleFactor = 1.4;
  const maxSize = 768;

  const isSmallScreen = window.visualViewport.width < maxSize;
  const [scale, setScale] = useState(isSmallScreen ? scaleFactor : 1);

  useEffect(() => {
    const rescale = (e: any) => {
      setScale(e.target.visualViewport.width < maxSize ? scaleFactor : 1);
    };

    window.addEventListener('resize', rescale);
    return () => {
      window.removeEventListener('resize', rescale);
    };
  }, [isSmallScreen]);

  const scaleT = isSmallScreen
    ? { transform: `scale(${scale})`, margin: '12px' }
    : {};
  return scaleT;
}
