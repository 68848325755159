import { IStateProps } from '../..';
import { DrawInProgress } from '../../../Common/DrawInProgress';
import { NextDraw } from '../../../Common/NextDraw';
import { BoostSplash } from '../../../Common/BoostSplash';
import { MediaComponent } from '../../../Common/MediaComponent';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';
import ResultBanner from '../../../Common/ResultBanner';
import { IBangoMeta } from '../../../../interfaces/sceneState';

interface IBContent extends IBangoMeta {
  backgroundVideo?: string;
  backgroundImages?: string[];
}

export const StaticContentHTML = (props: IStateProps) => {
  const meta = getMeta(props) as IBContent;
  return (
    <>
      <DrawInProgress scene={props.displayState} {...props} />
      <NextDraw {...meta} />
      <BoostSplash scene={props.displayState} {...props} />
      <MediaComponent
        backgroundImages={meta?.backgroundImages}
        backgroundVideo={meta?.backgroundVideo}
        {...props}
      />
      <ResultBanner
        ballValues={meta.balls}
        extraValue={meta.extra}
        {...props}
      />
    </>
  );
};

export const StaticContentScene = createScene(StaticContentHTML, null);
