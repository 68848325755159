import { DrawInProgress } from '../../../Common/DrawInProgress';
import { IStateProps } from '../..';
import { motion } from 'framer-motion';
import { ExtraAnimated } from '../../../Common/Extra';
import { ExtraSplash } from '../../../Common/ExtraSplash';
import { createScene } from '../../../../helpers/createScene';
import * as THREE from 'three';
import { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import { useFrame } from '@react-three/fiber';
import { getSharedMaterials } from '../Draw/getSharedMaterials';
import { INumberTextHandle, NumberText } from '../Draw/NumberText';
import { getMeta } from '../../../../helpers/getMeta';
import { IExtraMeta } from '../../../../interfaces/sceneState';
import {
  ONE_ROUND,
  easeOutCirc,
  easeOutQuad,
  ONE_SECOND,
  easeInCubic,
  easeOutCubic,
  customEaseOut,
  easeInOutBack,
} from '../../../../constants/easing';

interface IGridConfiguration {
  moveX: number;
  moveY: number;
  moveZ: number;
  rotate: number;
}

interface IBall {
  value: number;
  isExtra: boolean;
}

// Animation configuration
const delta = 0.5;

const extraSplashAnimationDuration = 3;

const animationDuration = 8;

const reassemblyPhase1 = 1;
const displayGridDuration = 4;
const displayGridDelay = 0.25;

const extraBallAnimationDuration = animationDuration - reassemblyPhase1;

const explosionForce = 0.175;
const collisionAtTime = displayGridDuration / 2.55;
const explosionDuration = 1;

const gridWidth = 5;
const gridHeight = 4;
const spacing = 1.3;

const ballZ = 0.75;
const extraBallMinZ = -15;
const extraBallMaxZ = 3.5;

const gridConfig: IGridConfiguration[] = [
  { moveX: 2, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 3.25) },
  { moveX: 2.25, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 2.34) },
  { moveX: 2.5, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 1.95) },
  { moveX: 2.25, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 2.34) },
  { moveX: 2, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 3.25) },
  { moveX: 2, moveY: 2.25, moveZ: 2, rotate: ONE_ROUND / (delta + 2.68) },
  { moveX: 2.25, moveY: 2.25, moveZ: 3, rotate: ONE_ROUND / (delta + 1.45) },
  { moveX: 2.5, moveY: 2.25, moveZ: 3, rotate: ONE_ROUND / (delta + 0.65) },
  { moveX: 2.25, moveY: 2.25, moveZ: 3, rotate: ONE_ROUND / (delta + 1.45) },
  { moveX: 2, moveY: 2.25, moveZ: 2, rotate: ONE_ROUND / (delta + 2.68) },
  { moveX: 2, moveY: 2.25, moveZ: 2, rotate: ONE_ROUND / (delta + 2.68) },
  { moveX: 2.25, moveY: 2.25, moveZ: 3, rotate: ONE_ROUND / (delta + 1.45) },
  { moveX: 2.5, moveY: 2.25, moveZ: 3, rotate: ONE_ROUND / (delta + 0.65) },
  { moveX: 2.25, moveY: 2.25, moveZ: 3, rotate: ONE_ROUND / (delta + 1.45) },
  { moveX: 2, moveY: 2.25, moveZ: 2, rotate: ONE_ROUND / (delta + 2.68) },
  { moveX: 2, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 3.25) },
  { moveX: 2.25, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 2.34) },
  { moveX: 2.5, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 1.95) },
  { moveX: 2.25, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 2.34) },
  { moveX: 2, moveY: 2, moveZ: 2, rotate: ONE_ROUND / (delta + 3.25) },
];

// HTML
const ExtraHTML = (props: IStateProps) => {
  const meta = getMeta(props) as IExtraMeta;

  return (
    <>
      <ExtraAnimated extraImage={meta.extraImage} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: extraSplashAnimationDuration }}
      >
        <DrawInProgress scene={props.displayState} {...props} />
        <ExtraSplash extraImage={meta.extraImage} />
      </motion.div>
    </>
  );
};

// ThreeD
interface IPoint3 {
  x: number;
  y: number;
  z: number;
}

const copyPoint = (src: IPoint3, des: IPoint3) => {
  des.x = src.x;
  des.y = src.y;
  des.z = src.z;
};

const getBalls = (props: IStateProps) => {
  const meta = getMeta(props) as IExtraMeta;
  const balls = meta.balls
    .sort((a, b) => (a < b ? 1 : -1))
    .map<IBall>((v) => ({ value: v, isExtra: v === meta.extra }));

  const extraBall = balls.find((b) => b.isExtra);

  return {
    balls,
    extraBall,
    totalBalls: balls.length,
  };
};

const useGrid = (totalBalls: number) => {
  // Calculate initial positions for the grid balls
  const grid = useMemo(
    () =>
      Array.from({ length: totalBalls }, (_, i) => {
        const max = Math.max(gridHeight, gridWidth);
        const row = Math.floor(i / max);
        const column = i % max;

        return new THREE.Vector3(
          -((column - (gridWidth - 1) / 2) * spacing),
          (row - (gridHeight - 1) / 2) * spacing,
          ballZ,
        );
      }),
    [totalBalls],
  );

  // Refs to store current positions and rotations
  const positionsRef = useRef(grid.map((p) => ({ x: p.x, y: p.y, z: p.z })));
  const rotationRef = useRef(grid.map(() => ({ x: 0, y: 0, z: 0 })));

  return {
    grid,
    gridPosisionRefs: positionsRef,
    gridRotationRefs: rotationRef,
  };
};

const useBallRef = () => {
  const {
    rotatableExtraMaterial: ballMaterial,
    rotatableExtraBillboardMaterial: ballTextMaterial,
  } = getSharedMaterials();

  const ballRef = useRef<THREE.Mesh>(null!);
  const ballTextRef = useRef<INumberTextHandle | null>(null);

  const updateBall = useCallback(
    (position?: IPoint3, rotation?: IPoint3, visible = true) => {
      // update meshes
      const p = position;
      const r = rotation;
      if (!!p) ballRef.current.position.set(p.x, p.y, p.z);
      if (!!r) ballRef.current.rotation.set(r.x, r.y, r.z);

      // update Text
      const textRef = ballTextRef.current;
      textRef?.updateMatrix(
        !!p ? [p.x, p.y, p.z] : undefined,
        !!r ? [r.x, r.y, r.z] : undefined,
      );

      ballMaterial.visible = ballTextMaterial.visible = visible;
    },
    [ballRef, ballTextRef, ballMaterial, ballTextMaterial],
  );

  useEffect(() => {
    ballMaterial.visible = false;
    ballTextMaterial.visible = false;
    return () => {
      ballMaterial.visible = true;
      ballTextMaterial.visible = true;
    };
  }, [ballMaterial, ballTextMaterial]);

  return {
    ballRef,
    ballTextRef,
    ballMaterial,
    ballTextMaterial,
    updateBall,
  };
};

const useBallsRef = (totalBall: number) => {
  const { instancedGridFinalMaterial: ballsMaterial } = getSharedMaterials();

  const instancedMeshRef = useRef<THREE.InstancedMesh>(null!);
  const ballTextRefs = useRef<Array<INumberTextHandle | null>>([]);
  const tempObject = useMemo(() => new THREE.Object3D(), []);

  const updateBalls = useCallback(
    (positions?: IPoint3[], rotations?: IPoint3[], visible = true) => {
      for (let i = 0; i < totalBall; i++) {
        // update meshes
        const p = positions?.[i];
        const r = rotations?.[i];

        if (!!p) tempObject.position.set(p.x, p.y, p.z);

        if (!!r) tempObject.rotation.set(r.x, r.y, r.z);
        tempObject.updateMatrix();
        instancedMeshRef.current.setMatrixAt(i, tempObject.matrix);

        // update Text
        const textRef = ballTextRefs.current[i];
        textRef?.updateMatrix(
          !!p ? [p.x, p.y, p.z] : undefined,
          !!r ? [r.x, r.y, r.z] : undefined,
        );
      }

      instancedMeshRef.current.instanceMatrix.needsUpdate = true;
      ballsMaterial.visible = visible;
    },
    [instancedMeshRef, ballTextRefs, tempObject, totalBall, ballsMaterial],
  );

  useEffect(() => {
    ballTextRefs.current = ballTextRefs.current.slice(0, totalBall);
  }, [totalBall]);

  useEffect(() => {
    ballsMaterial.visible = false;
    return () => {
      ballsMaterial.visible = true;
    };
  }, [ballsMaterial]);

  return {
    ballsRef: instancedMeshRef,
    ballTextRefs,
    ballsMaterial,
    updateBalls,
  };
};

const getExplosionStrength = (a: IPoint3, b: IPoint3) => {
  const force = new THREE.Vector3().subVectors(a, b);
  const distanceFromBreakingBall = force.length();
  const explosionStrength =
    explosionForce *
    (1 / (distanceFromBreakingBall + 0.1)) *
    (0.5 + Math.random() * 0.5); // Add some randomness to the explosion

  return explosionStrength;
};

const explosionBall = (
  a: IPoint3,
  b: IPoint3,
  strength: number,
  config: IGridConfiguration,
) => {
  const position: IPoint3 = { x: 0, y: 0, z: 0 };
  const rotation: IPoint3 = { x: 0, y: 0, z: 0 };
  const distance = new THREE.Vector3(b.x, b.y, b.z).length();

  position.x =
    a.x + b.x * (0.25 + Math.random() * 0.5) * config.moveX * strength;

  position.y =
    a.y + b.y * (0.25 + Math.random() * 0.5) * config.moveY * strength;

  position.z = a.z + Math.abs(b.z) * Math.random() * config.moveZ * strength;
  rotation.z = Math.max(distance + delta, 0) * config.rotate;

  return { position, rotation };
};

const reassemblyBallOfPhase1 = (target: IPoint3, progress: number) => {
  const easeInOutBackValue = easeInOutBack(progress);
  const position: IPoint3 = { x: 0, y: 0, z: 0 };

  position.x = target.x * easeInOutBackValue;
  position.y = target.y * easeInOutBackValue;
  position.z = target.z * easeInOutBackValue;

  return { position };
};

const reassemblyExtraBallOfPhase2 = (
  a: IPoint3,
  b: IPoint3,
  progress: number,
) => {
  const easeInCubicValue = easeInCubic(progress);
  const easeInCubicLerp = (x: number, y: number) =>
    THREE.MathUtils.lerp(x, y, easeInCubicValue);

  const easeOutCubicValue = easeOutCubic(progress);
  const easeOutCubicLerp = (x: number, y: number) =>
    THREE.MathUtils.lerp(x, y, easeOutCubicValue);

  const position: IPoint3 = { x: 0, y: 0, z: 0 };

  position.x = easeInCubicLerp(a.x, b.x);
  position.y = easeInCubicLerp(a.y, b.y);
  position.z = easeOutCubicLerp(a.z, b.z);

  return { position };
};

const reassemblyBallOfPhase2 = (
  a: IPoint3,
  b: IPoint3,
  ra: IPoint3,
  progress: number,
) => {
  const easeOutQuadValue = easeOutQuad(progress / 12);
  const easeOutQuadLerp = (x: number, y: number) =>
    THREE.MathUtils.lerp(x, y, easeOutQuadValue);

  const position: IPoint3 = { x: 0, y: 0, z: 0 };
  const rotation: IPoint3 = { x: 0, y: 0, z: 0 };

  position.x = easeOutQuadLerp(a.x, b.x);
  position.y = easeOutQuadLerp(a.y, b.y);
  position.z = easeOutQuadLerp(a.z, b.z);

  // Return to original rotation (zero)
  rotation.x = ra.x * (1 - easeOutQuadValue);
  rotation.y = ra.y * (1 - easeOutQuadValue);
  rotation.z = ra.z * (1 - easeOutQuadValue);

  return { position, rotation };
};

const GridExplosion = (props: IStateProps) => {
  const { balls, extraBall, totalBalls } = getBalls(props);

  const geometry = useMemo(() => new THREE.PlaneGeometry(1.1, 1.1), []);
  const {
    ballRef: extraBallRef,
    ballTextRef: extraBallTextRef,
    updateBall: updateExtraBall,
    ballMaterial: extraBallMaterial,
    ballTextMaterial: extraBallTextMaterial,
  } = useBallRef();
  const { ballsRef, ballTextRefs, updateBalls, ballsMaterial } =
    useBallsRef(totalBalls);

  const [isRenderred, setIsRenderred] = useState(false);

  const {
    grid: gridPositions,
    gridPosisionRefs: positions,
    gridRotationRefs: rotations,
  } = useGrid(totalBalls);

  const animationStartTime = useRef<number | null>(null);

  // Initialize the instanced mesh with grid positions
  useEffect(() => {
    if (!isRenderred) {
      return;
    }
    updateBalls(gridPositions);
  }, [updateBalls, gridPositions, isRenderred]);

  useFrame((state) => {
    // !! setting states are prohibited in useFrame!! use refs instead
    if (!ballsRef.current || !extraBallRef.current || !extraBallTextRef) return;

    // Start the animation if it hasn't started yet
    if (animationStartTime.current === null) {
      animationStartTime.current = state.clock.elapsedTime;
    }

    const time = state.clock.elapsedTime - animationStartTime.current;

    if (time <= 0 || time > animationDuration) {
      return; // Animation has finished
    }

    const playAnimateTime = time - reassemblyPhase1;

    const newPositions = [...positions.current];
    const newRotations = [...rotations.current];

    let isExtraBallVisible = true;

    extraBallRef.current.position.z =
      extraBallMinZ +
      easeOutCirc(
        Math.min(playAnimateTime, displayGridDuration - displayGridDelay) /
          (displayGridDuration - displayGridDelay),
      ) *
        (extraBallMaxZ - extraBallMinZ);

    extraBallRef.current.rotation.z =
      ONE_ROUND *
      6 *
      Math.min(
        customEaseOut((playAnimateTime + 0.1) / extraBallAnimationDuration),
        1,
      );

    if (time < reassemblyPhase1) {
      isExtraBallVisible = false;
      const progress = time / reassemblyPhase1;
      gridPositions.forEach((pos, i) => {
        const { position } = reassemblyBallOfPhase1(pos, progress);
        copyPoint(position, newPositions[i]);
      });
    }

    // Explosion animation
    if (
      playAnimateTime > collisionAtTime &&
      playAnimateTime < collisionAtTime + explosionDuration
    ) {
      gridPositions.forEach((pos, i) => {
        const { position, rotation } = explosionBall(
          newPositions[i],
          new THREE.Vector3().subVectors(pos, extraBallRef.current.position),
          getExplosionStrength(pos, extraBallRef.current.position),
          gridConfig[i],
        );
        copyPoint(position, newPositions[i]);
        copyPoint(rotation, newRotations[i]);
      });
    }

    // Reassembly animation
    if (playAnimateTime > displayGridDuration + displayGridDelay) {
      const start = displayGridDuration + displayGridDelay;
      const progress = Math.min(
        (playAnimateTime - start) / (extraBallAnimationDuration - 1 - start),
        1,
      );

      gridPositions.forEach((pos, index) => {
        const isExtra = balls[index].isExtra;

        if (isExtra) {
          const { position } = reassemblyExtraBallOfPhase2(
            extraBallRef.current.position,
            pos,
            progress,
          );
          copyPoint(position, extraBallRef.current.position);
        } else {
          const { position, rotation } = reassemblyBallOfPhase2(
            newPositions[index],
            pos,
            newRotations[index],
            progress,
          );
          copyPoint(position, newPositions[index]);
          copyPoint(rotation, newRotations[index]);
        }
      });
    }

    // Update instancedMesh
    updateBalls(newPositions, newRotations, true);
    updateExtraBall(
      extraBallRef.current.position,
      extraBallRef.current.rotation,
      isExtraBallVisible,
    );
    positions.current = newPositions;
    rotations.current = newRotations;
  });

  if (!isRenderred) {
    setTimeout(() => {
      setIsRenderred(true);
    }, extraSplashAnimationDuration * ONE_SECOND);
    return <></>;
  }

  return (
    <>
      <instancedMesh
        ref={ballsRef}
        args={[geometry, ballsMaterial, totalBalls]}
      />
      <mesh
        ref={extraBallRef}
        geometry={geometry}
        material={extraBallMaterial}
      />
      <NumberText
        ref={extraBallTextRef}
        key={`extra_` + extraBall.value}
        position={[
          extraBallRef.current?.position?.x,
          extraBallRef.current?.position?.y,
          extraBallRef.current?.position?.z,
        ]}
        number={extraBall.value}
        transition={true}
        scale={1.5}
        rotatable={true}
        material={extraBallTextMaterial}
      />

      {balls.map((ball, index) => {
        const position = positions.current[index];
        return (
          <NumberText
            ref={(el) => (ballTextRefs.current[index] = el)}
            key={ball.value}
            position={[position.x, position.y, position.z]}
            number={ball.value}
            transition={true}
            scale={1.5}
            rotatable={true}
          />
        );
      })}
    </>
  );
};

export const ExtraScene = createScene(ExtraHTML, GridExplosion);
