const widthOffset = 8;
const wd2 = 4;
const HL = 7;
const LL = 8;

export function getRandomBorderPosition(): [number, number] {
  const side = Math.floor(Math.random() * 4); // 0: top, 1: right, 2: bottom, 3: left
  switch (side) {
    case 0: // To
      return [Math.random() * widthOffset - wd2, HL];
    case 1: // Right
      return [LL, Math.random() * widthOffset - wd2];
    case 2: // Bottom
      return [Math.random() * widthOffset - wd2, -HL];
    case 3: // Left
      return [-LL, Math.random() * widthOffset - wd2];
  }
}
