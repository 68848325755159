import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { motion } from 'framer-motion-3d';
import { Trail, Sparkles } from '@react-three/drei';
import { BallFullYellow, BallWithNumber } from '../ball';
import RevealEffect from '../shaders/RevealEffect';
import { T3D } from '..';
import { ballNumberToGridPosition } from '../helpers/ballNumberToGridPosition';
import { getRandomBorderPosition } from '../helpers/getRandomBorderPosition';
import { useAnimation } from 'framer-motion';
import { FakeGlowMaterial } from '../shaders/FakeGlowMaterial';

const CONSTANTS = {
  INITIAL_BALL_OFFSET: -19,
  BASE_Z_OFFSET: 0.5,
  MIDDLE_Z_OFFSET: 2,
  RANDOM_OFFSET_RANGE: 0,
  RANDOM_OFFSET_CENTER: 0,
  INITIAL_ANIMATION_DURATION: 1.5,
  FINAL_ANIMATION_DURATION: 1,
  REVEAL_DURATION: 1500,
  NUMBER_REVEAL_DELAY: 750,
  FINAL_Z_OFFSET: 0.0007,
  TRAIL_WIDTH: 11,
  TRAIL_ATTENUATION: 0.6,
  SPARKLES_COUNT: 6,
  SPARKLES_SIZE: 3,
  SPARKLES_SCALE: 0.4,
  GLOW_SPHERE_RADIUS: 2.3,
  GLOW_SPHERE_SEGMENTS: 3,
  GLOW_SPHERE_H_SEGMENTS: 2,
};

const PHASES = {
  DEFAULT: -1,
  INIT: 0,
  FINAL_POSITION: 2,
  REVEAL: 3,
  FINAL: 4,
  END: 5,
};

interface BallProps {
  number: number;
  triggerAnimation: boolean;
  initialBall: boolean;
}

export const AnimatedBall: React.FC<BallProps> = React.memo(
  ({ number, triggerAnimation, initialBall }) => {
    const controls = useAnimation();
    const [currentState, setCurrentState] = useState(PHASES.DEFAULT);

    const initialBallOffset = initialBall ? CONSTANTS.INITIAL_BALL_OFFSET : 0;
    const zBase = CONSTANTS.BASE_Z_OFFSET + initialBallOffset;
    const zMiddle = zBase + CONSTANTS.MIDDLE_Z_OFFSET;
    const finalPosition = useMemo(
      () => ballNumberToGridPosition(number),
      [number],
    );
    const borderPosition = useMemo(() => getRandomBorderPosition(), []);
    const randomOffset = useMemo(
      () => [
        Math.random() * CONSTANTS.RANDOM_OFFSET_RANGE -
          CONSTANTS.RANDOM_OFFSET_CENTER,
        Math.random() * CONSTANTS.RANDOM_OFFSET_RANGE -
          CONSTANTS.RANDOM_OFFSET_CENTER +
          initialBallOffset,
      ],
      [initialBallOffset],
    );

    const animateBall = useCallback(async () => {
      const elapsedTime =
        Date.now() / 1000 + CONSTANTS.INITIAL_ANIMATION_DURATION;
      const skipAnimation = () => {
        const totalAnimationTime =
          CONSTANTS.INITIAL_ANIMATION_DURATION +
          CONSTANTS.FINAL_ANIMATION_DURATION;
        const skip = Date.now() / 1000 - elapsedTime > totalAnimationTime;
        if (skip) {
          setCurrentState(PHASES.FINAL);
          controls.set({
            x: finalPosition[0],
            y: finalPosition[1],
            z: finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
          });
          controls.stop();
        }
        return skip;
      };

      if (!initialBall) {
        if (skipAnimation()) return;
        setCurrentState(PHASES.INIT);
        await controls
          .start({
            x: randomOffset[0],
            y: randomOffset[1],
            z: zMiddle,
            transition: {
              duration: CONSTANTS.INITIAL_ANIMATION_DURATION,
              ease: 'easeInOut',
            },
          })
          .catch((e) => {
            controls.stop();
          });
      }

      if (skipAnimation()) return;
      setCurrentState(PHASES.FINAL_POSITION);
      await controls
        .start({
          x: finalPosition[0],
          y: finalPosition[1],
          z: finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
          transition: {
            ease: 'easeInOut',
            duration: initialBall
              ? CONSTANTS.INITIAL_ANIMATION_DURATION
              : CONSTANTS.FINAL_ANIMATION_DURATION,
          },
        })
        .catch(() => {
          controls.stop();
        });
      if (skipAnimation()) return;

      if (!initialBall) {
        setCurrentState(PHASES.REVEAL);
        await new Promise((resolve) =>
          setTimeout(resolve, CONSTANTS.REVEAL_DURATION),
        );
      }
      if (skipAnimation()) return;

      setCurrentState(PHASES.FINAL);
    }, [controls, randomOffset, finalPosition, zMiddle, initialBall]);

    useEffect(() => {
      if (triggerAnimation) {
        animateBall();
      }
    }, [triggerAnimation, animateBall]);

    useEffect(() => {
      return () => {
        setCurrentState(PHASES.END);
        controls.stop();
      };
    }, [controls]);

    const showReveal = currentState >= PHASES.REVEAL;

    const showTrailAndBall =
      (currentState === PHASES.FINAL_POSITION || !initialBall) &&
      currentState <= PHASES.FINAL_POSITION &&
      currentState > PHASES.DEFAULT;

    const largeTrail =
      currentState === PHASES.INIT ||
      (initialBall && currentState === PHASES.FINAL_POSITION);

    const showTrail =
      (currentState === PHASES.INIT && !initialBall) ||
      currentState === PHASES.FINAL_POSITION;

    const initialPosition = [
      initialBall ? randomOffset[0] : borderPosition[0],
      initialBall ? randomOffset[1] : borderPosition[1],
      initialBall ? zMiddle : zBase,
    ] as T3D;

    return (
      <>
        <motion.group
          position={initialPosition}
          initial={false}
          animate={controls}
          visible={triggerAnimation}
        >
          <BallAndTrail
            showTrailAndBall={showTrailAndBall}
            showTrail={showTrail}
            largeTrail={largeTrail}
            initialBall={initialBall}
          />
        </motion.group>

        {showReveal && finalPosBall(finalPosition, triggerAnimation, number)}

        {(currentState === PHASES.REVEAL || currentState === PHASES.FINAL) &&
          revealEffects(finalPosition, currentState)}
      </>
    );
  },
);

const BallAndTrail = ({
  showTrailAndBall,
  showTrail,
  largeTrail,
  initialBall,
}) => {
  if (!showTrailAndBall) return null;

  return showTrail ? (
    <Trail
      color="#ffff33"
      width={largeTrail ? CONSTANTS.TRAIL_WIDTH : CONSTANTS.TRAIL_WIDTH * 0.5}
      attenuation={(width) => width * CONSTANTS.TRAIL_ATTENUATION}
      decay={1}
      length={2.1}
    >
      <BallFullYellow front={!initialBall} />
    </Trail>
  ) : (
    <BallFullYellow front={!initialBall} />
  );
};
function finalPosBall(
  finalPosition: T3D,
  triggerAnimation: boolean,
  number: number,
) {
  return (
    <group
      position={[
        finalPosition[0],
        finalPosition[1],
        finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
      ]}
      visible={triggerAnimation}
    >
      <BallWithNumber number={number} transition={true} staticPos={true} />
    </group>
  );
}

function revealEffects(finalPosition: T3D, currentState: number) {
  return (
    <group
      position={[
        finalPosition[0],
        finalPosition[1],
        finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
      ]}
    >
      {currentState === PHASES.REVEAL && <RevealEffect />}
      <mesh>
        <sphereGeometry
          args={[
            CONSTANTS.GLOW_SPHERE_RADIUS,
            CONSTANTS.GLOW_SPHERE_SEGMENTS,
            CONSTANTS.GLOW_SPHERE_H_SEGMENTS,
          ]}
        />
        <FakeGlowMaterial
          glowColor={'#ffff33'}
          glowSharpness={0.2}
          glowInternalRadius={10}
          falloff={0.8}
        />
      </mesh>
      <Sparkles
        color={'#ffff33'}
        count={CONSTANTS.SPARKLES_COUNT}
        size={CONSTANTS.SPARKLES_SIZE}
        scale={CONSTANTS.SPARKLES_SCALE}
        position={[0, 0, 0.2]}
      />
    </group>
  );
}
