import { motion } from 'framer-motion';
import { COLORS } from '../../../constants/colours';
import { ballsStyle, ballStyle, bannerStyle } from './styles';
import { getIsChannelTV } from '../../../helpers/getIsChannelTV';
import { IStateProps } from '../../App';

interface IProps extends IStateProps {
  ballValues: number[];
  extraValue: number;
}

const ResultBanner = ({ ballValues, extraValue, ...props }: IProps) => {
  const isChannelTV = getIsChannelTV(props);
  const hide = !isChannelTV;
  if (hide) {
    return <></>;
  }

  return (
    <div style={bannerStyle}>
      <div style={ballsStyle}>
        {ballValues
          .sort((a, b) => (a < b ? -1 : 1))
          .map((b, i) => {
            const isExtra = b === extraValue;
            return (
              <motion.div
                initial={{ opacity: 0, translateY: 15 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  delay: 0.5 + i * 0.09,
                }}
                key={'ball_' + b + '_' + i}
                style={{
                  ...ballStyle,
                  backgroundColor: isExtra ? COLORS.red2024 : COLORS.white,
                  color: isExtra ? COLORS.white : COLORS.black,
                }}
              >
                {b}
              </motion.div>
            );
          })}
      </div>
    </div>
  );
};

export default ResultBanner;
