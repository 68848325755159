import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';
import {
  timeEmpty,
  timeFormat,
  timeInvalid,
} from '../../../../constants/texts';
import {
  adjustArrayValues,
  getSortData,
  type ISortable,
} from '../../../../helpers/arrays';
import { ISettings } from '../../../../interfaces/app';
import { type ILoExScene } from '../../../../interfaces/scene';
import { type ResultsStateScene } from '../../../../interfaces/sceneState';
import { BallShaded } from '../../../../models/ballShaded';
import { Scene, type ISceneMeshesBase } from '../../../../models/scene';
import { Timer } from '../../../../models/timer';
import LayoutService from '../../../../services/layout';
import { FeatureFlag } from '../../../../enums/featureFlag';

interface ISceneMeshes extends ISceneMeshesBase {
  balls: BallShaded[];
  extraBall?: BallShaded;
  nextDrawTimer?: Timer;
}

export default class ResultsScene2024 extends Scene implements ILoExScene {
  protected _scene: THREE.Scene = new THREE.Scene();
  protected _state: ResultsStateScene;
  protected _language: string;

  protected _meshes: ISceneMeshes = {
    balls: [],
  };

  public getScene(): THREE.Scene {
    return this._scene;
  }

  public setup = async (
    language: string,
    state: ResultsStateScene,
    settings: ISettings,
  ) =>
    await new Promise<void>(async (resolve, reject) => {
      this._state = state;
      this._language = language;

      const {
        _meshes: meshes,
        _scene: scene,
        _state: {
          meta: {
            [language]: { balls = [], extra, nextDrawTime },
          },
        },
      } = this;

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballGridLayout();

      scene.add((meshes.extraBall = new BallShaded()));
      scene.add((meshes.nextDrawTimer = new Timer()));

      const extraBallData = currentResults.find(
        (result: ISortable<number>) => result.value === extra,
      ) || { sortedIndex: 0, originalIndex: 0 };
      const extraBallPosition = ballLayout.positions[extraBallData.sortedIndex];

      return await Promise.all<any>([
        ...currentResults.map(async ({ value, sortedIndex }, i) => {
          scene.add((meshes.balls[i] = new BallShaded()));

          return await meshes.balls[i].configure({
            value,
            state: {
              position: adjustArrayValues(
                ballLayout.positions[sortedIndex],
                [0, 0, -1],
              ),
              rotation: [0, 0, 0],
              scale: ballLayout.scale,
              opacity: 0,
            },
            featureFlag: FeatureFlag.Loex2024,
          });
        }),
        meshes.extraBall.configure({
          value: extra,
          isExtraBall: true,
          state: {
            position: adjustArrayValues(extraBallPosition, [0, 0, 0.005]),
            rotation: [0, 0, Math.PI * 8],
            scale: ballLayout.scale,
            opacity: 0,
          },
          featureFlag: FeatureFlag.Loex2024,
        }),

        meshes.nextDrawTimer
          .configure({
            format: timeFormat,
            empty: timeEmpty,
            invalid: timeInvalid,
            state: {
              position: [4.1, 1.9, -3.5],
              scale: [0, 0.2, 1],
              rotation: [0, 0, 0],
              opacity: 0,
            },
            textAlign: 'right',
            // glyphWidth: 22 // NOTE: This can be enabled to force a non-jiggly timer
          })
          .then(
            async () =>
              await meshes.nextDrawTimer.setDate(new Date(nextDrawTime)),
          ),
      ])
        .then(() => resolve())
        .catch(reject);
    });

  public play = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const {
        _meshes: meshes,
        _state: {
          meta: {
            [this._language]: { balls = [] },
          },
        },
      } = this;

      if (!Object.keys(meshes).length) {
        return resolve();
      }

      const currentResults = getSortData(balls, (a, b) => a - b);

      const ballLayout = LayoutService.ballGridLayout();

      return await Promise.all([
        meshes.nextDrawTimer
          .beginAutoRedraw()
          .then(async () => await meshes.nextDrawTimer.tweenTo({ opacity: 0 })),
      ])
        .then(
          async () =>
            await Promise.all(
              currentResults.map(
                async (currentResult, i) =>
                  await meshes.balls[i].tweenTo(
                    {
                      position: ballLayout.positions[currentResult.sortedIndex],
                      opacity: 1,
                    },
                    1000,
                    currentResult.sortedIndex * 75,
                    TWEEN.Easing.Elastic.Out,
                  ),
              ),
            ),
        )
        .then(
          async () =>
            await meshes.extraBall.tweenTo(
              { rotation: [0, 0, 0], opacity: 1 },
              2000,
              0,
              TWEEN.Easing.Quadratic.Out,
            ),
        )
        .then(() => resolve())
        .catch(reject);
    });

  public teardown = async () =>
    await new Promise<void>(async (resolve, reject) => {
      const { _meshes: meshes } = this;

      if (!Object.keys(meshes).length) {
        return await Promise.resolve()
          .then(() => resolve())
          .then(async () => await this.destroy())
          .catch(reject);
      }

      return await Promise.all<any>([
        meshes.nextDrawTimer.tweenOut({ opacity: 0 }),
        meshes.extraBall
          ? meshes.extraBall.tweenOut({
              opacity: 0,
            })
          : Promise.resolve(),
        ...meshes.balls.map(
          async (ball) =>
            await ball.tweenOut({
              opacity: 0,
            }),
        ),
      ])
        .then(() => resolve())
        .then(async () => await this.destroy())
        .catch(reject);
    });

  public onChange = async (state: ResultsStateScene) =>
    await new Promise<void>((resolve, reject) => {
      this._state = state;

      resolve();
    });
}
