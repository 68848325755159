import store from '../helpers/store';

type Vertex3Array = [number, number, number];

interface Size {
  width: number;
  height: number;
}

interface Point {
  x: number;
  y: number;
}

interface IBallLayout {
  scale: Vertex3Array;
  spacing: Point;
  area: Size;
  offset: Point;
  positions: Vertex3Array[];
}

interface IBallLineLayout extends IBallLayout {}

interface IBallGridLayout extends IBallLayout {
  animationOffset: number[];
}

interface IDisplay {
  width: number;
  height: number;
  padding: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

export class LayoutService {
  private static readonly display: IDisplay = {
    width: 1080,
    height: 1080,
    padding: {
      top: 40,
      right: 40,
      bottom: 40,
      left: 40,
    },
  };

  private static readonly balls_grid_scale: Vertex3Array = [0.7, 0.7, 0.7];

  private static readonly balls_grid_spacing: { x: number; y: number } = {
    x: 0.1,
    y: 0.1,
  };

  private static readonly balls_grid_area: { width: number; height: number } = {
    width:
      LayoutService.balls_grid_scale[0] * 5 +
      LayoutService.balls_grid_spacing.x * 4,
    height:
      LayoutService.balls_grid_scale[1] * 4 +
      LayoutService.balls_grid_spacing.y * 3,
  };

  private static readonly balls_line_scale: Vertex3Array = [0.38, 0.38, 0.38];

  private static readonly balls_line_spacing: { x: number; y: number } = {
    x: 0.05,
    y: 0.05,
  };

  private static readonly balls_line_area: { width: number; height: number } = {
    width:
      LayoutService.balls_line_scale[0] * 20 +
      LayoutService.balls_line_spacing.x * 19,
    height: LayoutService.balls_line_scale[1],
  };

  public display = (): IDisplay => {
    const { scale = 1 } = store.getState().settings;

    return {
      height: LayoutService.display.height * scale,
      width: LayoutService.display.width * scale,
      padding: {
        top: LayoutService.display.padding.top * scale,
        right: LayoutService.display.padding.right * scale,
        bottom: LayoutService.display.padding.bottom * scale,
        left: LayoutService.display.padding.left * scale,
      },
    };
  };

  public setDimensions(x: number, y: number) {
    LayoutService.display.width = x;
    LayoutService.display.height = y;
  }

  public ballGridLayout = (
    display: IDisplay = this.display(),
  ): IBallGridLayout => ({
    ...this.ballLayout(display).grid,
  });

  public ballLineLayout = (
    display: IDisplay = this.display(),
  ): IBallLineLayout => ({
    ...this.ballLayout(display).line,
  });

  private readonly ballLayout = (
    display: IDisplay = this.display(),
  ): {
    grid: IBallGridLayout;
    line: IBallLineLayout;
  } => ({
    grid: {
      scale: LayoutService.balls_grid_scale,
      spacing: LayoutService.balls_grid_spacing,
      area: LayoutService.balls_grid_area,
      animationOffset: [
        300, // 3
        200, // 2
        100, // 1
        200, // 2
        300, // 3
        200, // 2
        100, // 1
        0, // 0
        100, // 1
        200, // 2
        200, // 2
        100, // 1
        0, // 0
        100, // 1
        200, // 2
        300, // 3
        200, // 2
        100, // 1
        200, // 2
        300, // 3
      ],
      offset: {
        x: display.width / 2 - LayoutService.balls_grid_area.width / 2,
        y: display.height / 2 - LayoutService.balls_grid_area.height / 2,
      },
      positions: [
        [
          LayoutService.balls_grid_scale[0] * 0 +
            LayoutService.balls_grid_spacing.x * 0 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 0 +
            LayoutService.balls_grid_spacing.y * 0 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 1 +
            LayoutService.balls_grid_spacing.x * 1 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 0 +
            LayoutService.balls_grid_spacing.y * 0 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 2 +
            LayoutService.balls_grid_spacing.x * 2 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 0 +
            LayoutService.balls_grid_spacing.y * 0 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 3 +
            LayoutService.balls_grid_spacing.x * 3 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 0 +
            LayoutService.balls_grid_spacing.y * 0 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 4 +
            LayoutService.balls_grid_spacing.x * 4 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 0 +
            LayoutService.balls_grid_spacing.y * 0 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 0 +
            LayoutService.balls_grid_spacing.x * 0 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 1 +
            LayoutService.balls_grid_spacing.y * 1 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 1 +
            LayoutService.balls_grid_spacing.x * 1 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 1 +
            LayoutService.balls_grid_spacing.y * 1 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 2 +
            LayoutService.balls_grid_spacing.x * 2 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 1 +
            LayoutService.balls_grid_spacing.y * 1 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 3 +
            LayoutService.balls_grid_spacing.x * 3 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 1 +
            LayoutService.balls_grid_spacing.y * 1 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 4 +
            LayoutService.balls_grid_spacing.x * 4 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 1 +
            LayoutService.balls_grid_spacing.y * 1 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 0 +
            LayoutService.balls_grid_spacing.x * 0 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 2 +
            LayoutService.balls_grid_spacing.y * 2 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 1 +
            LayoutService.balls_grid_spacing.x * 1 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 2 +
            LayoutService.balls_grid_spacing.y * 2 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 2 +
            LayoutService.balls_grid_spacing.x * 2 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 2 +
            LayoutService.balls_grid_spacing.y * 2 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 3 +
            LayoutService.balls_grid_spacing.x * 3 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 2 +
            LayoutService.balls_grid_spacing.y * 2 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 4 +
            LayoutService.balls_grid_spacing.x * 4 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 2 +
            LayoutService.balls_grid_spacing.y * 2 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 0 +
            LayoutService.balls_grid_spacing.x * 0 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 3 +
            LayoutService.balls_grid_spacing.y * 3 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 1 +
            LayoutService.balls_grid_spacing.x * 1 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 3 +
            LayoutService.balls_grid_spacing.y * 3 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 2 +
            LayoutService.balls_grid_spacing.x * 2 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 3 +
            LayoutService.balls_grid_spacing.y * 3 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 3 +
            LayoutService.balls_grid_spacing.x * 3 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 3 +
            LayoutService.balls_grid_spacing.y * 3 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
        [
          LayoutService.balls_grid_scale[0] * 4 +
            LayoutService.balls_grid_spacing.x * 4 +
            LayoutService.balls_grid_scale[0] / 2 -
            LayoutService.balls_grid_area.width / 2,
          -(
            LayoutService.balls_grid_scale[1] * 3 +
            LayoutService.balls_grid_spacing.y * 3 +
            LayoutService.balls_grid_scale[1] / 2 -
            LayoutService.balls_grid_area.height / 2
          ),
          -3.5,
        ],
      ] as Vertex3Array[],
    },
    line: {
      scale: LayoutService.balls_line_scale,
      spacing: LayoutService.balls_line_spacing,
      area: LayoutService.balls_line_area,
      offset: {
        x: display.width / 2 - LayoutService.balls_line_area.width / 2,
        y:
          display.height -
          LayoutService.balls_line_area.height -
          display.padding.bottom,
      },
      positions: [
        [
          LayoutService.balls_line_scale[0] * 0 +
            LayoutService.balls_line_spacing.x * 0 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 1 +
            LayoutService.balls_line_spacing.x * 1 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 2 +
            LayoutService.balls_line_spacing.x * 2 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 3 +
            LayoutService.balls_line_spacing.x * 3 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 4 +
            LayoutService.balls_line_spacing.x * 4 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 5 +
            LayoutService.balls_line_spacing.x * 5 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 6 +
            LayoutService.balls_line_spacing.x * 6 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 7 +
            LayoutService.balls_line_spacing.x * 7 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 8 +
            LayoutService.balls_line_spacing.x * 8 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 9 +
            LayoutService.balls_line_spacing.x * 9 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 10 +
            LayoutService.balls_line_spacing.x * 10 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 11 +
            LayoutService.balls_line_spacing.x * 11 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 12 +
            LayoutService.balls_line_spacing.x * 12 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 13 +
            LayoutService.balls_line_spacing.x * 13 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 14 +
            LayoutService.balls_line_spacing.x * 14 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 15 +
            LayoutService.balls_line_spacing.x * 15 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 16 +
            LayoutService.balls_line_spacing.x * 16 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 17 +
            LayoutService.balls_line_spacing.x * 17 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 18 +
            LayoutService.balls_line_spacing.x * 18 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
        [
          LayoutService.balls_line_scale[0] * 19 +
            LayoutService.balls_line_spacing.x * 19 +
            LayoutService.balls_line_scale[0] / 2 -
            LayoutService.balls_line_area.width / 2,
          -2.15,
          -3.5,
        ],
      ] as Vertex3Array[],
    },
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LayoutService();
