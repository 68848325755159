import { Text } from '../../../interfaces/sceneState';
import { COMBINED_FONT, FONTS } from '../../../enums/fonts';

export interface IText {
  content: Text;
  customFont?: FONTS;
  customStyle?: React.CSSProperties;
  align?: 'center' | 'left' | 'right';
}

export const MetaText = ({ customFont, customStyle, ...textProps }: IText) => {
  if (!textProps) return null;
  return (
    <div
      style={{
        color: textProps?.content?.color ?? '',
        fontFamily: customFont
          ? customFont
          : (textProps?.content?.font ??
            COMBINED_FONT.BarlowCondensedOrDefault),
        fontWeight: 'semibold',
        fontSize: textProps?.content?.size ?? '',
        textAlign: textProps?.align ? textProps?.align : 'initial',
        ...customStyle,
      }}
    >
      {textProps?.content?.text ?? ''}
    </div>
  );
};
