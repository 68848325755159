import { createDerivedMaterial } from 'troika-three-utils';
import * as THREE from 'three';

export function createInstancedBillboardMaterial(baseMaterial: THREE.Material) {
  return createDerivedMaterial(baseMaterial, {
    vertexMainOutro: `
        vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(0.0, 0.0, 0.0, 1.0);
        vec3 scale;
        scale.x = length(vec3(instanceMatrix[0].x, instanceMatrix[1].x, instanceMatrix[2].x));
        scale.y = length(vec3(instanceMatrix[0].y, instanceMatrix[1].y, instanceMatrix[2].y));
        scale.z = length(vec3(instanceMatrix[0].z, instanceMatrix[1].z, instanceMatrix[2].z));
        mvPosition.xyz += (modelViewMatrix * vec4(position * scale, 0.0)).xyz;
        gl_Position = projectionMatrix * mvPosition;
      `,
  });
}
