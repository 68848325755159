import * as signalR from '@microsoft/signalr';
import { type SceneState } from '../interfaces/sceneState';
class Connector {
  private readonly retryDelayMs = 5000;
  private readonly connection: signalR.HubConnection;

  public events: (
    onMessageReceived: (currentScene: SceneState) => void,
  ) => void;

  public onRetry: (elapsedMilliseconds: number) => void;
  public onError: () => void;

  private retryPolicy(context: signalR.RetryContext) {
    if (this.onRetry) this.onRetry(context.elapsedMilliseconds);
    return this.retryDelayMs;
  }

  static instance: Connector;
  constructor(loop: string, debug: boolean) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(window.location.origin + '/api/animation')
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (context) => this.retryPolicy(context),
      })
      .configureLogging(debug ? signalR.LogLevel.Debug : signalR.LogLevel.Error)
      .build();

    this.connection
      .start()
      .then(async () => {
        if (loop) {
          await this.connection.invoke('ConnectLoop', loop);
        } else {
          await this.connection.invoke('ConnectDefaultLoop');
        }
      })
      .catch((err) => {
        console.error(err);
        if (this.onError) this.onError();
      });

    this.connection.onreconnected(async () => {
      if (loop) {
        await this.connection.invoke('ConnectLoop', loop);
      } else {
        await this.connection.invoke('ConnectDefaultLoop');
      }
    });

    this.events = (onMessageReceived) => {
      this.connection.on('SendCurrentState', (currentScene: SceneState) => {
        onMessageReceived(currentScene);
      });
    };
  }

  public static getInstance(loop: string, debug?: boolean): Connector {
    if (!Connector.instance)
      Connector.instance = new Connector(loop, debug ?? false);
    return Connector.instance;
  }
}

export default Connector.getInstance;
