import { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { shaderMaterial } from '@react-three/drei';
import * as THREE from 'three';
import { extend } from '@react-three/fiber';

// Shockwave shader
const ShockwaveMaterial = shaderMaterial(
  { time: 0, color: new THREE.Color('#ffff99') },
  `
varying vec2 vUv;

void main() {
  vUv = uv;

  vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);
  vec3 scale = vec3(
    length(modelViewMatrix[0].xyz),
    length(modelViewMatrix[1].xyz),
    length(modelViewMatrix[2].xyz)
  );
  mvPosition.xyz += position * scale;
  gl_Position = projectionMatrix * mvPosition;
}
`,
  `
    uniform float time;
    uniform vec3 color;
    varying vec2 vUv;
    void main() {
      vec2 center = vec2(0.5, 0.5);
      float dist = distance(vUv, center);
      float wave = sin(dist * 45.0 - time * 9.0) * 0.5 + 0.5;
      float alpha = smoothstep(0.0, 0.5, 1.0 - dist) * wave * (1.0 - time);
      gl_FragColor = vec4(color, alpha);
    }
  `,
);

extend({ ShockwaveMaterial });

const RevealEffect = () => {
  const shockwaveRef = useRef<THREE.Mesh>(null);
  const timeRef = useRef(0);

  useFrame((_state, delta) => {
    timeRef.current += delta;
    if (shockwaveRef.current) {
      (shockwaveRef.current.material as any).uniforms.time.value =
        timeRef.current * 0.8;
      shockwaveRef.current.scale.setScalar(1 + timeRef.current * 2);
      (shockwaveRef.current.material as any).opacity = Math.max(
        0,
        1 - timeRef.current * 2,
      );
    }
  });

  return (
    <mesh ref={shockwaveRef} position={[0, 0, 0.001]}>
      <circleGeometry args={[0.19, 32]} />
      {/* @ts-ignore */}
      <shockwaveMaterial
        transparent={true}
        depthWrite={false}
        side={THREE.FrontSide}
      />
    </mesh>
  );
};

export default RevealEffect;
