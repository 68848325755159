import React, { useEffect, useRef, useCallback } from 'react';
import { IBaseMeta } from '../../../interfaces/sceneState';
import { FONTS } from '../../../enums/fonts';
import { useRescale } from '../../App/hooks/useRescale';

const nextDrawContainerStyle: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  top: '30px',
  right: '44px',
  color: '#FFFFFF',
  textTransform: 'uppercase',
  letterSpacing: '0px',
  flexDirection: 'column',
  textAlign: 'end',
  fontSize: '25px',
  lineHeight: '30px',
  fontWeight: 'semibold',
  fontFamily: FONTS.BarlowCondensedBold,
};
const rootStyle = {
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const boxStyle = {
  width: '32px',
  height: '45px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  fontFamily: FONTS.BarlowCondensedBold,
  fontWeight: 'semibold',

  fontSize: '40px',
  lineHeight: '48px',
  letterSpacing: '0px',
  margin: '0 2px',
  color: '#000000',
};
const separatorStyle: React.CSSProperties = {
  height: '41px',
  margin: '0 -1px',
  marginBottom: '5px',
  textAlign: 'center',
  fontFamily: FONTS.BarlowCondensedBold,
  fontWeight: 'semibold',
  fontSize: '34px',
  lineHeight: '42px',
  letterSpacing: '0px',
  color: '#FFFFFF',
};

function defaultTime() {
  const now = new Date();
  const totalSeconds = now.getMinutes() * 60 + now.getSeconds();
  return 300 - (totalSeconds % 300);
}

export const NextDraw = React.memo((meta: IBaseMeta) => {
  const { nextDrawText, nextDrawTime } = meta;
  const drawText =
    nextDrawText?.flatMap((item) => item.text).join(' ') ?? 'PROCHAIN TIRAGE';

  const nextTime = new Date(nextDrawTime).getTime();

  const timeRemaining = nextTime
    ? Math.floor((nextTime - Date.now()) / 1000)
    : defaultTime();

  // event listener to update the scale
  const scaleT = useRescale();

  return (
    <div style={{ ...nextDrawContainerStyle, ...scaleT }}>
      <span>{drawText}</span>
      <DateCount timeRemaining={timeRemaining} />
    </div>
  );
});

interface IDateProps {
  timeRemaining: number;
}

const DateCount = React.memo(({ timeRemaining }: IDateProps) => {
  const secondsLeftRef = useRef(timeRemaining);
  const minutesRef = useRef<HTMLDivElement>(null);
  const secondsRef = useRef<HTMLDivElement>(null);

  const updateTime = useCallback(() => {
    const remainingMinutes = Math.floor(secondsLeftRef.current / 60);
    const remainingSeconds = secondsLeftRef.current % 60;

    if (minutesRef.current) {
      const minuteDigits = getFormatedTime(remainingMinutes);
      Array.from(minutesRef.current.children).forEach((child, index) => {
        (child as HTMLElement).textContent = minuteDigits[index];
      });
    }
    if (secondsRef.current) {
      const secondDigits = getFormatedTime(remainingSeconds);
      Array.from(secondsRef.current.children).forEach((child, index) => {
        (child as HTMLElement).textContent = secondDigits[index];
      });
    }
  }, []);

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(() => {
      secondsLeftRef.current -= 1;
      if (secondsLeftRef.current <= 0) {
        clearInterval(intervalId);
        secondsLeftRef.current = 0;
      }
      updateTime();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [updateTime]);

  return (
    <span style={rootStyle}>
      <div ref={minutesRef} style={{ display: 'flex' }}>
        <GetCountdownBox
          time={Math.floor(timeRemaining / 60)}
          boxStyle={boxStyle}
        />
      </div>
      <span style={separatorStyle}>:</span>
      <div ref={secondsRef} style={{ display: 'flex' }}>
        <GetCountdownBox time={timeRemaining % 60} boxStyle={boxStyle} />
      </div>
    </span>
  );
});

const GetCountdownBox = React.memo(
  ({ time, boxStyle }: { time: number; boxStyle: React.CSSProperties }) => {
    return (
      <>
        {getFormatedTime(time).map((x, index) => (
          <span key={index} style={boxStyle}>
            {x}
          </span>
        ))}
      </>
    );
  },
);

const getFormatedTime = (time: string | number | undefined): string[] => {
  return `${time ?? ''}`.padStart(2, '0').split('');
};
